<template>
    <div class="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="faq-question" @click="toggleFAQ(index)">
          <h3>{{ faq.question }}</h3>
          <span class="arrow" :class="{ open: faq.open }">▼</span>
        </div>
        <transition name="fade">
          <div v-if="faq.open" class="faq-answer">
            <p>{{ faq.answer }}</p>
          </div>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        faqs: [
          {
            question: "Can I extend my package?",
            answer:
              "As all Charity Getaways packages are provided with 3-Years to travel, they are not eligible for extension.",
            open: false,
          },
          {
            question: "What is my Travel-by Date?",
            answer:
              "Your Travel-by Date is the latest possible date that you may select for travel before your package expires.",
            open: false,
          },
          {
            question:
              "Can a booking agent contact me directly via phone or email to complete my booking request?",
            answer:
              "No, we are unable to conduct bookings via phone or email. All selections must be made via our booking platform. All communication regarding your package must go through the internal messaging system, accessed by clicking 'Contact Us' on your Traveler Dashboard, for privacy, security, and record-keeping purposes. The messaging system is NOT automated and you are always communicating with a live person.",
            open: false,
          },
          {
            question: "What if I don't see the dates I want?",
            answer:
              "Packages are valid for travel for 3-years from their event date, however it is not possible to load inventory for 3-full years in advance as we must await the release of additional inventory from our suppliers. Inventory is generally displayed 90-180+ days from today each day and dates for the next year can be released as early as June of the current year to as late as December of the current year. We have no control over when our suppliers release additional dates. If you do not see the dates that you are looking for, we recommend that you check back often as inventory is updated regularly and as soon as additional dates become available to us from our suppliers, they will appear within the inventory system for selection.",
            open: false,
          },
          {
            question: "How do I book my vacation?",
            answer:
              "To begin the booking process, click the blue button titled 'Browse Travel Accommodations' on your Traveler Dashboard. You will be taken to the inventory page where you may browse Alternative Destinations and Original Package Destinations. To review an inventory option, click 'Book/More Info'. You will be taken to a page that provides you with information about the resort and its available amenities. If you would like to book the date, destination, and resort that you are reviewing, click the button titled 'Select This Booking' and complete the pop-up as required. You will receive a message confirming that you've made an inventory selection. Please do not make any travel arrangements until you've received a confirmation from us.",
            open: false,
          },
          {
            question: "What are Alternative Destinations?",
            answer:
              "Alternative Destinations are limited destination and resort options in the US, Canada, and Bali that provide availability 30-days from today each day. These options are subject to change at any time without warning as they are based upon availability from our suppliers of Alternative Destinations. There is no additional fee to make a selection from Alternative Destinations as opposed to Original Package Destinations.",
            open: false,
          },
          {
            question: "What is a High Occupancy Surcharge?",
            answer:
              "A High Occupancy Surcharge is an upgrade to a larger unit size that is not covered by your package. This is a flat-rate fee, not per night. You can avoid this fee by selecting a unit size that is covered by your package.",
            open: false,
          },
          {
            question: "What is a Peak Season Surcharge?",
            answer:
              "A Peak Season Surcharge is a fee for travel during a peak traveling period as outlined by our suppliers. This is a flat-rate fee, not per night. You can avoid this fee by selecting travel dates that fall outside of peak traveling periods as outlined by our suppliers.",
            open: false,
          },
          {
            question: "What is a Third-Year Surcharge?",
            answer:
              "A Third-Year Surcharge is a fee for travel in the third and final year of your package's lifetime and is imposed due to the uncertainty of accommodation costs over the three-year travel period of your package. This is a flat-rate fee. You can avoid this fee by traveling before the third year of your package's lifetime.",
            open: false,
          },
          {
            question: "Can I book dates that are sooner than 90-days from today?",
            answer:
              "Our suppliers require selections of original package destinations to be made 90-days prior to your desired arrival date. As such, it is not possible to book a stay that is less than 90-days in advance of today.",
            open: false,
          },
          {
            question: "Can I split my stay?",
            answer:
              "Due to agreements with our suppliers, it is not possible to split your stay among different destinations or resorts. All packages are for consecutive night stays and may not be broken up.",
            open: false,
          },
          {
            question: "Can I cancel my confirmed reservation?",
            answer:
              "All reservations are final. Our suppliers do not permit us to make changes of any kind, for any reason, to confirmed bookings. This includes, but is not limited to, cancellations, date changes, name changes, unit changes, resort changes, destination changes, etc.",
            open: false,
          },
          {
            question:
              "What do I do if the resort does not have me in their system when I call ahead to confirm?",
            answer:
              "As we do not often book directly with resorts and utilize third-party suppliers, your reservation may not appear within the resort's immediate system until roughly 14-days prior to your arrival date. Should you contact the resort less than 14-days prior to your arrival date and they cannot locate your reservation in their system, please reach out to us immediately by clicking 'Contact Us' on your Traveler Dashboard and we will be happy to look into it for you.",
            open: false,
          },
          {
            question: "Can I transfer my package?",
            answer:
              "If your package has not yet been used for travel, you may transfer it to a friend or family member as a gift. There is no fee to transfer your package to another person. You can transfer your package to another person by clicking the button titled 'Transfer Your Package' on the top of your Traveler Dashboard. In the pop-up window, provide the name of the new package holder and their email address. Please be sure to double-check the entered email address as this action cannot be undone prior to submitting that form. If the new package holder has issues locating the transferred package, please provide them with the following email address for assistance: info@charitygetaways.com.",
            open: false,
          },
          {
            question: "How do I sort the inventory?",
            answer:
              "Inventory can be sorted by only one column at a time. You may sort the inventory by Destination, Resort Name, Check-In Date, Check-Out Date, # of Nights, Bedrooms, Max Occupancy, Kitchen, or by Total Surcharge. To sort by one of these options, simply click the title of the column such as 'Resort Name' to list all options alphabetically then scroll until you see your desired option.",
            open: false,
          },
          {
            question:
              "I have multiple packages and can't seem to register the others I hold. What do I do?",
            answer:
              "Each package that you are holding must be registered with a unique primary email address. You may place your preferred email address as the secondary email on your other packages and it will still receive all the same information that the primary email address will receive regarding that package. It is not possible to view multiple packages on the same dashboard. Each package will have its own dashboard.",
            open: false,
          },
        ],
      };
    },
    methods: {
      toggleFAQ(index) {
        this.faqs[index].open = !this.faqs[index].open;
      },
    },
  };
  </script>
  
  <style scoped>
  .faq-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
  }
  
  .faq-answer {
    padding: 10px 0;
  }
  
  .arrow {
    transition: transform 0.2s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  